import { ChatRoom, ChatWithRelations, NewChat } from 'generated';
import useGetChatRoomSummary from 'pages/chatPage/hooks/useGetChatRoomSummary';
import PreviewImageModal from 'pages/chatPage/modals/PreviewImageModal';
import useUploadImage from 'pages/outdated-chat/hooks/useUploadImage';
import { Dispatch, SetStateAction, useState } from 'react';
import { useAppSelector } from 'redux/hooks/useAppSelector';
import { generateRandomCode } from 'utils/helpers';
import useDisclosure from 'utils/useDisclosure/useDisclosure';
import ChatDrawer from '../ChatDrawer/ChatDrawer';
import ChatTopBar from './components/ChatTopBar';
import Messages from './components/Messages/Messages';
import SendBarComp from './components/SendBar/SendBar';

interface IProps {
  activeChatRoom?: ChatRoom;
  sendNewchat: (chat: NewChat, callback?: () => void) => void;
  loading?: boolean;
  chats: ChatWithRelations[];
  page: number;
  isInitialLoading: boolean;
  handleActiveChatroom?: (chatroom?: ChatRoom) => void;
  setPage: Dispatch<SetStateAction<number>>;
  totalPages: number;
  totalElements: number;
  readChatMessage: (data: { userId: string; chatId: string }) => void;
  withSendBar?: boolean;
}

const ChatArea = ({
  withSendBar = true,
  sendNewchat,
  chats,
  loading,
  page,
  setPage,
  isInitialLoading,
  totalPages,
  activeChatRoom,
  totalElements,
  handleActiveChatroom,
  readChatMessage,
}: IProps) => {
  const { uploadImage, loading: uploadImageLoading } = useUploadImage({
    endpoint: '/chats-upload-file',
  });
  const chatDrawerHandler = useDisclosure();
  const user = useAppSelector((state) => state.user.currentUser);
  const { chatRoomSummary, refetchSummary } = useGetChatRoomSummary({
    id: activeChatRoom?.id,
    userId: user?.id,
  });
  const [message, setMessage] = useState<string>('');
  const [resourceUrl, setResourceUrl] = useState<string>('');
  const [chatImage, setChatImage] = useState({
    image: null,
    previewImage: '',
    fileType: '',
  });

  const handleMessageChange = (e) => {
    setMessage(e);
  };

  const getImageLink = (image: any) => {
    if (image) {
      const formData = new FormData();
      formData?.append('attachment', image, image?.name);
      uploadImage(formData).then((res: any) => {
        if (res?.data?.file && res?.status === 200) {
          setResourceUrl(res?.data?.file);
        }
      });
    }
  };

  const sendChat = (message, callback?: () => void) => {
    const payload: NewChat & { frontendId?: string } = {
      message,
      chatRoomId: activeChatRoom?.id,
      senderId: user?.id,
      senderCoachId: user?.id,
      senderTypeEnum: 'coach',
      senderImage: user?.image,
      senderName: `${user?.firstName} ${user?.lastName}`,
      resourceUrl: resourceUrl || '',
      seen: [user?.id],
      frontendId: generateRandomCode(),
    };
    if (payload.message.length !== 0 || resourceUrl.length !== 0) sendNewchat(payload, callback);
  };

  const onChangeImage = (event) => {
    try {
      const { target } = event;
      const { files } = target;

      if (files && files[0]) {
        const imageUrl = URL.createObjectURL(files[0]);
        setChatImage({
          ...chatImage,
          image: files[0],
          previewImage: imageUrl,
          fileType: files[0].type,
        });
        getImageLink(files[0]);
      }
    } catch (err) {
      throw err;
    }
  };

  const resetImageData = () => {
    setResourceUrl('');
    setChatImage({ fileType: '', previewImage: '', image: null });
  };

  return (
    <section className={`w-full h-[80vh] relative border-b-[0.6px] bg-white`}>
      <div className="w-full h-full bg-white flex max-h-full flex-1">
        <div className={`w-full h-full ${chatDrawerHandler.isOpen ? 'hidden md:block' : ''}`}>
          <div className="w-full h-max">
            {activeChatRoom && (
              <ChatTopBar
                chatDrawerHandler={chatDrawerHandler}
                activeChatRoom={activeChatRoom}
                chatRoomSummary={chatRoomSummary}
                handleActiveChatroom={(chatRoom) => handleActiveChatroom?.(chatRoom)}
              />
            )}
          </div>
          <div className="w-full !h-[90%] relative flex flex-col justify-start">
            <div className="!h-[75%]">
              <Messages
                messages={chats}
                loading={loading}
                activeChatRoom={activeChatRoom}
                page={page}
                isInitialLoading={isInitialLoading}
                totalPages={totalPages}
                totalElements={totalElements}
                setPage={setPage}
                readChatMessage={readChatMessage}
              />
            </div>
            {withSendBar ? (
              <div className="absolute bottom-5 lg:relative bg-white flex items-end w-full">
                <SendBarComp
                  message={message}
                  handleMessageChange={handleMessageChange}
                  onChangeImage={onChangeImage}
                  sendChat={sendChat}
                />
              </div>
            ) : null}
          </div>
        </div>
        <div
          className={`h-full ${
            chatDrawerHandler.isOpen
              ? 'transition-opacity block w-full md:w-8/12 lg:w-5/12 opacity-100'
              : ' transition-opacity hidden opacity-0'
          }`}
        >
          <ChatDrawer
            open={chatDrawerHandler.isOpen}
            onClose={chatDrawerHandler.onClose}
            chatRoomSummary={chatRoomSummary}
            refetchSummary={refetchSummary}
            activeChatRoom={activeChatRoom}
          />
        </div>
      </div>
      <PreviewImageModal
        data={chatImage.image}
        preview={chatImage.previewImage}
        sendImageMessage={(e) => sendChat(e)}
        handleMessageChange={handleMessageChange}
        onChangeImage={onChangeImage}
        loading={uploadImageLoading}
        isOpen={chatImage.previewImage.length !== 0 ? true : false}
        onClose={() => {
          resetImageData();
        }}
        fileType={chatImage.fileType}
        fileName={chatImage?.image?.name}
        message={message}
      />
    </section>
  );
};

export default ChatArea;
